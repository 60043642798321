import axios from 'axios';
import router, { tokenExpired } from './router';
import StorageUtils from './utils/StorageUtils';
import message from '@/assets/plugins/message/message.min.js';
/**
 * 配套config/index.js中的 proxyTable属性进行跨域请求（测试用）
 * @type {string}
 */
axios.defaults.baseURL = window.globle.RequestUrl;

/**
 * axios实例
 * @timeout 超时时间
 */
const service = axios.create({ timeout: 30000 });

/**
 * request拦截器
 * @type {number}
 */
service.interceptors.request.use(
  config => {
    //token验证
    if (tokenExpired()) {
      config.headers.Authorization = 'Bearer ' + StorageUtils.sessionStorageGet('token');
    } else {
      if (router.currentRoute._value.fullPath.indexOf('/login') == -1) {
        router.push({
          path: '/login',
          query: { redirect: router.currentRoute._value.fullPath }
        });
      }
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

/**
 * response拦截器
 */
service.interceptors.response.use(
  response => {
    $.zhoujl.unblockUI();
    if ((response.data.Code != null && response.data.Code != '0') || (response.data.code != null && response.data.code != '0')) {
      if (response.data.Code == '401' || response.data.code == '401') {
        router.push({
          path: '/login'
        });
        message.warning(response.data.Message || response.data.message);
      } else {
        if (response.data.Code != 10001 && response.data.code != 10001) {
          message.warning(response.data.Message || response.data.message);
        }
      }
      return Promise.reject(response);
    }
    if (response.headers.authorization != null) {
      StorageUtils.sessionStorageSet('token', response.headers.authorization);
    }
    return response;
  },
  error => {
    $.zhoujl.unblockUI();
    if (error.response && error.response.status == 401) {
      router.push({
        path: '/login'
      });
    }
    return Promise.reject(error.response);
  }
);

/**
 * get请求
 * @param url
 * @param params
 * @returns {*}
 */
export function get(url, params = {}) {
  params.t = new Date().getTime(); //get方法加一个时间参数,解决ie下可能缓存问题.
  return service({
    url: url,
    method: 'get',
    headers: {},
    params
  });
}

/**
 * post请求
 * @param url
 * @param data
 * @returns {*}
 */
export function post(url, data = {}) {
  //默认配置
  return service({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: JSON.stringify(data)
  });
}

/**
 * form表单post请求
 * @param url
 * @param data
 * @returns {*}
 */
function postFormData(url, data = {}) {
  return service({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data,
    transformRequest: [
      function (data) {
        let ret = '';
        for (let item in data) {
          ret += encodeURIComponent(item) + '=' + encodeURIComponent(data[item]) + '&';
        }
        ret = ret.substring(0, ret.lastIndexOf('&'));
        return ret;
      }
    ]
  });
}

/**
 * put请求
 * @param url
 * @param data
 * @returns {*}
 */
function put(url, data = {}) {
  return service({
    url: url,
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: JSON.stringify(data)
  });
}

/**
 * delete请求
 * @param url
 * @param data
 * @returns {*}
 */
function deletes(url, data = {}) {
  return service({
    url: url,
    method: 'delete',
    headers: {},
    params: data
  });
}

/**
 * patch
 * @param url
 * @returns {*}
 */
function patch(url, data = {}) {
  return service({
    url: url,
    method: 'patch',
    headers: {},
    data: JSON.stringify(data)
  });
}

/**
 * 不支持 google 0.46版本
 * 普通下载使用：location.href 或 window.open
 * 下载用法样例
 * this.$Http("Report/IDCertExportExcleDownload",{},"DOWNLOAD").then(response => {
 *           let blob = new Blob([response.data], {
 *             type: "application/vnd.ms-excel"
 *           });
 *           if (window.navigator.msSaveOrOpenBlob) {
 *             navigator.msSaveBlob(blob);
 *           } else {
 *             let link = document.createElement('a');
 *             link.href = window.URL.createObjectURL(blob);
 *             link.click();
 *             window.URL.revokeObjectURL(link.href)
 *           }
 *         }
 * );
 * @param url
 * @param params
 * @returns {*}
 */
export function download(url, params = {}) {
  params.t = new Date().getTime();
  return service({
    method: 'get',
    url: url,
    data: params,
    responseType: 'blob'
  });
}

/**
 * @param url
 * @param formData   例：let data = new FormData();data.append('file', file[0]);
 * @returns {*}
 */
export function upload(url, formData) {
  // 文件上传
  return service({
    method: 'POST',
    url: url,
    data: formData
  });
}

/**
 * 处理格式化URL（/demo/{id}）
 * @param url
 * @param data
 * @returns {*}
 */
function render(url, data) {
  let re = /{([^]+)?}/;
  let match = '';
  while ((match = re.exec(url))) {
    url = url.replace(match[0], data[match[1]]);
  }
  return url;
}

const fetch = options => {
  let url = options.url;
  url = options.method.toUpperCase() === 'UPLOAD' ? options.url : render(url, options.data);
  switch (options.method.toUpperCase()) {
    case 'GET':
      return get(url, options.data);
    case 'POST':
      return post(url, options.data);
    case 'FORM':
      return postFormData(url, options.data);
    case 'PUT':
      return put(url, options.data);
    case 'DELETE':
      return deletes(url, options.data);
    case 'PATCH':
      return patch(url, options.data);
    case 'DOWNLOAD':
      return download(url, options.data);
    case 'UPLOAD':
      return upload(url, options.data);
    default:
      return service(options);
  }
};

const busFetch = options => {
  //默认配置
  return service({
    url: "/exchange",
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: JSON.stringify(options)
  });
}

/**
 * 提供一个http方法
 * url 访问路径 不包括域名和项目名
 * data 参数对象
 * method 请求方式
 *  */
export function http(url, data = {}, method = 'GET', bus = true) {
  if (bus) {
    const options = {
      methodName: url,
      data: data,
      httpMethod: method,
      isRelease: window.globle.IsRelease,
      version: window.globle.Version
    };
    return busFetch(options).catch(error => {
      if (error != null && ((error.status > 200 && error.status < 300) || error.status == 500)) {
        ConfirmUtils.alert(error.status, error.data.Message, 'red');
      }
      throw error;
    });
  } else {
    const options = {url: url, data: data, method: method};
    return fetch(options).catch(error => {
      if (error != null && ((error.status > 200 && error.status < 300) || error.status == 500)) {
        ConfirmUtils.alert(error.status, error.data.Message, 'red');
      }
      throw error;
    });
  }
}