import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d4436fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["value", "onClick"]
const _hoisted_3 = ["value", "onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    id: $data.id,
    class: "checkbox",
    "data-toggle": "tooltip",
    "data-placement": "bottom",
    "data-trigger": "manual"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.checkboxArray, (item, index) => {
      return (_openBlock(), _createElementBlock("label", {
        class: "check-item",
        key: index
      }, [
        (item.checked)
          ? (_openBlock(), _createElementBlock("input", {
              key: 0,
              type: "checkbox",
              value: item.key,
              onClick: $event => ($options.changeEvent(item)),
              checked: "checked"
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createElementBlock("input", {
              key: 1,
              type: "checkbox",
              value: item.key,
              onClick: $event => ($options.changeEvent(item))
            }, null, 8, _hoisted_3)),
        _createTextVNode(" " + _toDisplayString(item.value), 1)
      ]))
    }), 128))
  ], 8, _hoisted_1))
}