import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "href", "src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("img", {
    id: $data.id,
    href: $data.image,
    src: $data.image,
    onLoad: _cache[0] || (_cache[0] = (...args) => ($options.imageLoadSuccess && $options.imageLoadSuccess(...args))),
    onError: _cache[1] || (_cache[1] = (...args) => ($options.imageLoadError && $options.imageLoadError(...args))),
    class: "image-view load"
  }, null, 40, _hoisted_1))
}