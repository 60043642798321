import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"34px","width":"60px","border":"solid 1px #ced4da","padding":"4px","border-radius":"4px","position":"relative"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ColorPicker = _resolveComponent("ColorPicker")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ColorPicker, {
      pureColor: _ctx.color,
      "onUpdate:pureColor": _cache[0] || (_cache[0] = $event => ((_ctx.color) = $event)),
      format: "hex6"
    }, null, 8, ["pureColor"])
  ]))
}