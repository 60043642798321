import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54333df8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "content-wrapper",
  id: "main-content"
}
const _hoisted_2 = { style: {"width":"100%","height":"100%","overflow-y":"auto"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_main_sidebar = _resolveComponent("main-sidebar")
  const _component_router_view = _resolveComponent("router-view")
  const _component_Layout = _resolveComponent("Layout")

  return (_openBlock(), _createBlock(_component_Layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_main_sidebar, {
        menuData: _ctx.menuData[_ctx.$Store.storeGet('currentAdmin')]
      }, null, 8, ["menuData"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.$Store.storeGet('isRouterAlive'))
            ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }, {
                default: _withCtx(({ Component }) => [
                  (_openBlock(), _createBlock(_KeepAlive, {
                    include: _ctx.$Store.storeGet('keepAlive'),
                    max: 3
                  }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                  ], 1032, ["include"]))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}