import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-placement", "value", "id"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("input", {
    onInput: _cache[0] || (_cache[0] = (...args) => ($options.inputEvent && $options.inputEvent(...args))),
    onPropertychange: _cache[1] || (_cache[1] = (...args) => ($options.inputEvent && $options.inputEvent(...args))),
    "data-toggle": "tooltip",
    "data-placement": $props.placement,
    "data-html": "true",
    "data-trigger": "manual",
    value: $props.modelValue,
    id: _ctx.id
  }, null, 40, _hoisted_1))
}