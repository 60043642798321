import Index from './views/Index.vue';
import Login from './views/Login.vue';
import AppMarket from './views/AppMarket.vue';
/**
 * 参数：auth 表示相应页面是否需要判断登录
 * 参数：keepAlive 标识相应页面是否缓存
 */

let routes = [
  { path: '/login', component: Login, meta: { auth: false } },
  //重定向
  { path: '/', redirect: '/index/dataSearch', meta: { auth: false } },
  //赖加载
  {
    path: '/index',
    component: Index,
    meta: {
      auth: true
    },
    children: [
      //工作台
      {
        path: 'dataSearch',
        name: 'DataSearch',
        component: () => import('@/views/home/DataSearch.vue'),
        meta: { auth: false, keepAlive: true, breadcrumb: [{ name: '首页', route: '' }] }
      },
      {
        path: 'registerAudit',
        name: 'RegisterAudit',
        component: () => import('@/views/home/RegisterAudit.vue'),
        meta: { auth: false, keepAlive: true, breadcrumb: [{ name: '个人审核', route: '' }] }
      },
      {
        path: 'companyUserAudit',
        name: 'CompanyUserAudit',
        component: () => import('@/views/home/CompanyUserAudit.vue'),
        meta: { auth: false, keepAlive: true, breadcrumb: [{ name: '个人审核', route: '' }] }
      },


      {
        path: 'companyAudit',
        name: 'CompanyAudit',
        component: () => import('@/views/home/CompanyAudit.vue'),
        meta: { auth: false, keepAlive: true, breadcrumb: [{ name: '企业审核', route: '' }] }
      },
      {
        path: 'companyAuditDetail',
        name: 'CompanyAuditDetail',
        component: () => import('@/views/home/CompanyAuditDetail.vue'),
        meta: { auth: false, keepAlive: true, breadcrumb: [{ name: '企业审核', route: '' }] }
      },

      {
        path: 'userAuditDetail',
        name: 'UserAuditDetail',
        component: () => import('@/views/home/CompanyUserAuditDetail.vue'),
        meta: { auth: false, keepAlive: true, breadcrumb: [{ name: '人员审核', route: '' }] }
      },


      {
        path: 'releaseAudit',
        name: 'ReleaseAudit',
        component: () => import('@/views/home/ReleaseAudit.vue'),
        meta: { keepAlive: true, auth: true, breadcrumb: [{ name: '发布审核', route: '' }] }
      },
      { path: 'inbox', name: 'Inbox', component: () => import('@/views/home/Inbox.vue'), meta: { keepAlive: false, auth: false, breadcrumb: [{ name: '收件箱', route: '' }] } },
      { path: 'outbox', name: 'Outbox', component: () => import('@/views/home/Outbox.vue'), meta: { keepAlive: false, auth: false, breadcrumb: [{ name: '发件箱', route: '' }] } },
      {
        path: 'release',
        name: 'Release',
        component: () => import('@/views/home/Release.vue'),
        meta: { keepAlive: true, auth: false, breadcrumb: [{ name: '需求互动', route: '' }] }
      },
      {
        path: 'companyRelease',
        name: 'CompanyRelease',
        component: () => import('@/views/home/CompanyRelease.vue'),
        meta: { keepAlive: true, auth: false, breadcrumb: [{ name: '需求互动', route: '' }] }
      },
      {
        path: 'releaseType',
        name: 'ReleaseType',
        component: () => import('@/views/home/ReleaseType.vue'),
        meta: { keepAlive: false, auth: false, breadcrumb: [{ name: '互动类型', route: '' }] }
      },
      {
        path: 'comment',
        name: 'Comment',
        component: () => import('@/views/home/Comment.vue'),
        meta: { keepAlive: false, auth: false, breadcrumb: [{ name: '互动评论', route: '' }] }
      },
      {
        path: 'tech',
        name: 'Tech',
        component: () => import('@/views/home/Tech.vue'),
        meta: { keepAlive: true, auth: false, breadcrumb: [{ name: '产品技术', route: '' }] }
      },
      {
        path: 'achievement',
        name: 'Achievement',
        component: () => import('@/views/home/Achievement.vue'),
        meta: { keepAlive: true, auth: false, breadcrumb: [{ name: '科研成果', route: '' }] }
      },
      {
        path: 'expert',
        name: 'Expert',
        component: () => import('@/views/home/Expert.vue'),
        meta: { keepAlive: true, auth: false, breadcrumb: [{ name: '专家展示', route: '' }] }
      },
      {
        path: 'industryTag',
        name: 'IndustryTag',
        component: () => import('@/views/home/IndustryTag.vue'),
        meta: { keepAlive: false, auth: false, breadcrumb: [{ name: '行业标签', route: '' }] }
      },
      {
        path: 'news',
        name: 'News',
        component: () => import('@/views/home/News.vue'),
        meta: { keepAlive: true, auth: false, breadcrumb: [{ name: '资讯管理', route: '' }] }
      },
      {
        path: 'newsEditorAudit',
        name: 'NewsEditorAudit',
        component: () => import('@/views/home/NewsEditorAudit.vue'),
        meta: { keepAlive: false, auth: false, breadcrumb: [{ name: '发布审核', route: '' }] }
      },
      {
        path: 'newsEditor',
        name: 'NewsEditor',
        component: () => import('@/views/home/NewsEditor.vue'),
        meta: {
          auth: false,
          keepAlive: false,
          breadcrumb: [{ name: '资讯编辑', route: '' }]
        }
      },
      {
        path: 'newsType',
        name: 'NewsType',
        component: () => import('@/views/home/NewsType.vue'),
        meta: { keepAlive: false, auth: false, breadcrumb: [{ name: '资讯类型', route: '' }] }
      },
      {
        path: 'notice',
        name: 'Notice',
        component: () => import('@/views/home/Notice.vue'),
        meta: { keepAlive: true, auth: false, breadcrumb: [{ name: '通知公告', route: '' }] }
      },
      {
        path: 'honor',
        name: 'Honor',
        component: () => import('@/views/home/Honor.vue'),
        meta: { keepAlive: true, auth: false, breadcrumb: [{ name: '会员风采', route: '' }] }
      },

      {
        path: 'companyVip',
        name: 'CompanyVip',
        component: () => import('@/views/vip/CompanyVip.vue'),
        meta: { keepAlive: true, auth: false, breadcrumb: [{ name: '企业会员', route: '' }] }
      },
      {
        path: 'companyVipEditor',
        name: 'CompanyVipEditor',
        component: () => import('@/views/vip/CompanyVipEditor.vue'),
        meta: { keepAlive: false, auth: false, breadcrumb: [{ name: '企业会员', route: '' }] }
      },
      {
        path: 'userVip',
        name: 'UserVip',
        component: () => import('@/views/vip/UserVip.vue'),
        meta: { keepAlive: true, auth: false, breadcrumb: [{ name: '个人会员', route: '' }] }
      },
      {
        path: 'tagVip',
        name: 'TagVip',
        component: () => import('@/views/vip/TagVip.vue'),
        meta: { keepAlive: false, auth: false, breadcrumb: [{ name: '会员标签', route: '' }] }
      },

      {
        path: 'banner',
        name: 'Banner',
        component: () => import('@/views/app/Banner.vue'),
        meta: { keepAlive: false, auth: false, breadcrumb: [{ name: '轮播图管理', route: '' }] }
      },
      {
        path: 'link',
        name: 'Link',
        component: () => import('@/views/app/Link.vue'),
        meta: { keepAlive: false, auth: false, breadcrumb: [{ name: '联动服务', route: '' }] }
      },
      {
        path: 'policy',
        name: 'Policy',
        component: () => import('@/views/app/Policy.vue'),
        meta: { keepAlive: false, auth: false, breadcrumb: [{ name: '隐私条款', route: '' }] }
      },
      {
        path: 'companyUser',
        name: 'CompanyUser',
        component: () => import('@/views/company/CompanyUser.vue'),
        meta: { keepAlive: true, auth: false, breadcrumb: [{ name: '企业人员', route: '' }] }
      },
      {
        path: 'companyDetail',
        name: 'CompanyDetail',
        component: () => import('@/views/company/CompanyDetail.vue'),
        meta: { keepAlive: false, auth: false, breadcrumb: [{ name: '企业详情', route: '' }] }
      },
      //系统管理
      {
        path: 'systemUser',
        name: 'SystemUser',
        component: () => import('@/views/system/SystemUser.vue'),
        meta: { auth: false, keepAlive: true, breadcrumb: [{ name: '管理人员', route: '' }] }
      },
      { path: 'role', component: () => import('@/views/system/Role.vue'), meta: { auth: false, breadcrumb: [{ name: '角色管理', route: '' }] } },
      { path: 'log', component: () => import('@/views/system/Log.vue'), meta: { auth: false, breadcrumb: [{ name: '系统日志', route: '' }] } },
      { path: 'recycle', component: () => import('@/views/system/Recycle.vue'), meta: { auth: false, breadcrumb: [{ name: '系统日志', route: '' }] } }
    ]
  },
  { path: '/appMarket', component: AppMarket, meta: { auth: false } }
];
export default routes;
