import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  class: "modal-dialog",
  role: "document"
}
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header",
  style: {"padding":"0 15px 0 15px","display":"flex","align-items":"center","height":"60px"}
}
const _hoisted_5 = {
  class: "modal-title",
  style: {"flex":"1"}
}
const _hoisted_6 = { style: {"font-size":"18px","margin-right":"10px"} }
const _hoisted_7 = { class: "modal-body" }
const _hoisted_8 = { class: "modal-footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal",
    id: _ctx.id,
    tabindex: "-1",
    role: "dialog"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h4", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(this.modalTitle == null || this.modalTitle == '' ? '模态框' : this.modalTitle), 1)
          ]),
          _createElementVNode("span", {
            class: "icon-20",
            style: {"color":"#666666","cursor":"pointer"},
            "data-dismiss": "modal",
            onClick: _cache[0] || (_cache[0] = (...args) => ($options.hide && $options.hide(...args)))
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-default",
            "data-dismiss": "modal",
            onClick: _cache[1] || (_cache[1] = (...args) => ($options.hide && $options.hide(...args)))
          }, _toDisplayString(this.closeName == null || this.closeName == '' ? '关闭' : this.closeName), 1),
          ($props.submitEvent != null)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "btn btn-primary",
                onClick: _cache[2] || (_cache[2] = (...args) => ($options.submit && $options.submit(...args)))
              }, _toDisplayString(this.confirmName == null || this.confirmName == '' ? '确认' : this.confirmName), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 8, _hoisted_1))
}