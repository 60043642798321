/**
 * 字符串工具类
 */
export default class StringUtils {
  /**
   * 判断字符串是否等于null或等于空字符串
   * @param text 字符串
   * @returns {boolean}
   */
  static isNullOrEmpty(text) {
    return text === null || text === undefined || text === '';
  }

  /**
   * 判断字符串是否是数字
   * @param text 字符串
   * @returns {boolean}
   */
  static isNumeric(text) {
    return /^(?:[1-9]\d*|0)(?:\.\d+)?$/.test(text);
  }

  /**
   * 判断字符串是否是小数
   * @param text 字符串
   * @returns {boolean}
   */
  static isDecimal(text) {
    return /^[-+]?(?:0|[1-9]\d*)\.\d+$/.test(text);
  }

  /**
   * 判断字符串是否是负小数
   * @param text 字符串
   * @returns {boolean}
   */
  static isNegativeDecimal(text) {
    return /^-?(?:0|[1-9]\d*)\.\d+$/.test(text);
  }

  /**
   * 判断字符串是否是正小数
   * @param text 字符串
   * @returns {boolean}
   */
  static isPositiveDecimal(text) {
    return /^\+?(?:0|[1-9]\d*)\.\d+$/.test(text);
  }

  /**
   * 判断字符串是否是整数
   * @param text 字符串
   * @returns {boolean}
   */
  static isInteger(text) {
    return /^[-+]?(?:0|[1-9]\d*)$/.test(text);
  }

  /**
   * 判断字符串是否是正整数
   * @param text 字符串
   * @returns {boolean}
   */
  static isPositiveInteger(text) {
    return /^\+?(?:0|[1-9]\d*)$/.test(text);
  }

  /**
   * 判断字符串是否是负整数
   * @param text 字符串
   * @returns {boolean}
   */
  static isNegativeInteger(text) {
    return /^-?(?:0|[1-9]\d*)$/.test(text);
  }

  /**
   * 判断字符串是否是邮箱地址
   * @param text 字符串
   * @returns {boolean}
   */
  static isEmail(text) {
    return /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(text);
  }

  /**
   * 判断字符串是否是身份证号
   * @param text 字符串
   * @returns {boolean}
   */
  static isIdCard(text) {
    return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(text);
  }

  /**
   * 判断字符串是否手机号
   * @param text 字符串
   * @returns {boolean}
   */
  static isPhone(text) {
    return /^1[34578]\d{9}$/.test(text);
  }
}
