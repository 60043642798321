import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-091d635f"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    ($props.avatar != null && $props.avatar.length > 0)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          style: _normalizeStyle({ width: $props.width + 'px', height: $props.height + 'px', lineHeight: $props.height + 'px', borderRadius: $props.radius ? '50%' : '5px' }),
          src: $props.avatar,
          class: "user-header-icon"
        }, null, 12, _hoisted_1))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          style: _normalizeStyle({ width: $props.width + 'px', height: $props.height + 'px', lineHeight: $props.height + 'px', borderRadius: $props.radius ? '50%' : '5px' }),
          class: "user-header-icon"
        }, _toDisplayString($props.nickname.slice(0, 1)), 5))
  ]))
}