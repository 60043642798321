export default {
  keepAlive: [],
  isRouterAlive: true,

  currentAdmin: null,

  selectOpenPage: null,
  //面包屑
  breadcrumb: null,

  todoList:"",

  expireDay: 0,

  showLog: false,
  showFeedback: false,
  showResource: false,
  showLimit: false,
  limitText: '对不起，您当前没有权限操作此模块功能，请联系管理升级！'
};
