import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    id: _ctx.id,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.selectValue) = $event)),
    multiple: "multiple",
    "data-toggle": "tooltip",
    "data-placement": "bottom",
    "data-trigger": "manual"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (item) => {
      return (_openBlock(), _createElementBlock("option", {
        key: item.key,
        value: item.key
      }, _toDisplayString(item.value), 9, _hoisted_2))
    }), 128))
  ], 8, _hoisted_1)), [
    [_vModelSelect, _ctx.selectValue]
  ])
}