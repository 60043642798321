const PermissionsList = [
  { key: 1, value: '合同创建', role_name_id: 1 },
  { key: 2, value: '合同修改', role_name_id: 2 },
  { key: 3, value: '合同删除', role_name_id: 3 },
  { key: 4, value: '合同作废', role_name_id: 4 },
  { key: 5, value: '合同完结', role_name_id: 5 },
  { key: 6, value: '合同下载', role_name_id: 6 },
  { key: 7, value: '附件下载', role_name_id: 7 },
  { key: 8, value: '合同分享', role_name_id: 8 },
  { key: 9, value: '模板创建', role_name_id: 9 },
  { key: 10, value: '模板修改', role_name_id: 10 },
  { key: 11, value: '模板删除', role_name_id: 11 },
  { key: 12, value: '模板下载', role_name_id: 12 },
  { key: 13, value: '模板启停', role_name_id: 13 },
  { key: 14, value: '模板市场添加至我的模板', role_name_id: 14 },
  { key: 15, value: '统计分析', role_name_id: 15 },
  { key: 16, value: '相对方启停', role_name_id: 16 },
  { key: 17, value: '相对方删除', role_name_id: 17 },
  { key: 18, value: '相对方标签', role_name_id: 18 },
  { key: 19, value: '合同类型管理', role_name_id: 19 },
  { key: 20, value: '编码管理', role_name_id: 20 },
  { key: 21, value: '印章管理', role_name_id: 21 },
  { key: 22, value: '用户管理', role_name_id: 22 },
  { key: 23, value: '角色管理', role_name_id: 23 },
  { key: 24, value: '其他设置', role_name_id: 24 }
];

const StateList = [
  { key: 1, value: '审批中', background: '#fbf3e7', color: '#faa852' },
  { key: 2, value: '已退回', background: '#fbecec', color: '#ef5d58' },
  { key: 3, value: '已拒绝', background: '#f5f5f6', color: '#b4b4b7' },
  { key: 4, value: '待签订', background: '#edeffe', color: '#535ef1' },
  { key: 5, value: '待归档', background: '#ecf2fe', color: '#4fa1f8' },
  { key: 6, value: '已归档', background: '#e9f8f7', color: '#77d5c6' },
  { key: 7, value: '已撤回', background: '#f1f1ff', color: '#b4b4bc' },
  { key: 0, value: '其他', background: '#f5f5f6', color: '#b4b4b7' }
];

const LyStateList = [
  { key: 1, value: '未履约', background: '#f1f1ff', color: '#b4b4bc' },
  { key: 2, value: '履约中', background: '#edeffe', color: '#535ef1' },
  { key: 3, value: '已解除', background: '#fbecec', color: '#ef5d58' },
  { key: 4, value: '已完结', background: '#e9f8f7', color: '#77d5c6' },
  { key: 5, value: '已到期', background: '#fbf3e7', color: '#faa852' }
];

const SignTypeList = [
  { key: 1, value: '纸质签署' },
  { key: 2, value: '电子签署' }
];

const WithMeList = [
  { key: 1, value: '我创建的' },
  { key: 2, value: '我负责的' },
  { key: 4, value: '我参与的' },
  { key: 8, value: '我审批的' },
  { key: 16, value: '我关注的' },
  { key: 32, value: '抄送我的' }
];

const ScopeList = [
  { key: 1, value: '全公司' },
  { key: 2, value: '本部门及下级部门' },
  { key: 3, value: '本人' },
  { key: 4, value: '自定义' }
];

function getItem(key, list) {
  let data = null;
  if (key != null && list != null && list.length > 0) {
    list.forEach(item => {
      if (key == item.key) {
        data = item;
      }
    });
  }
  return data;
}

function getValue(key, list) {
  let value = '';
  if (key != null && list != null && list.length > 0) {
    list.forEach(item => {
      if (key == item.key) {
        value = item.value;
      }
    });
  }
  return value;
}

function getPermissions(key, userPermissions = []) {
  let isExist = false;
  let data = getItem(key, PermissionsList);
  if (data != null) {
    userPermissions.forEach(item => {
      if (data.role_name_id == item.role_name_id && item.select == 1) {
        isExist = true;
      }
    });
  }
  return isExist;
}

export default {
  getItem,
  getValue,
  getPermissions,
  StateList,
  LyStateList,
  SignTypeList,
  WithMeList,
  ScopeList,
  PermissionsList
};
