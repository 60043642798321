/**
 * jquery-confirm弹出框
 * 功能强大，此工具类仅封装了我们常用的两种
 * 其他请参考：http://www.jq22.com/yanshi6789
 */
import '../assets/plugins/jqueryconfirm/jquery-confirm.js';
import '../assets/plugins/jqueryconfirm/jquery-confirm.css';

export default class ConfirmUtils {
  /**
   * 弹出信息框
   * @param title
   * @param content
   * @param type
   * @param confirmText
   */
  static alert(title, content, type, confirmText = '关闭') {
    $.alert({
      autoClose: 'ok|3000',
      animation: 'top',
      closeAnimation: 'scale',
      title: title,
      content: content,
      type: type,
      buttons: {
        ok: {
          text: confirmText,
          action: function () {}
        }
      }
    });
  }

  /**
   * 弹出确认框
   * @param title
   * @param content
   * @param type
   * @param confirmText
   * @param cancelText
   * 用法：
   * ConfirmUtils.confirm("确认","是否确认",function(bool){
   *     alert(bool)
   * });
   */
  static confirm(title = '确认框', content = '是否确认', callback, type = 'blue', confirmText = '确认', cancelText = '取消') {
    $.confirm({
      animation: 'top',
      closeAnimation: 'scale',
      title: title,
      content: content,
      type: type,
      buttons: {
        ok: {
          text: confirmText,
          btnClass: 'btn-primary',
          action: function () {
            if (callback) {
              callback(true);
            }
          }
        },
        cancel: {
          text: cancelText,
          action: function () {
            if (callback) {
              callback(false);
            }
          }
        }
      }
    });
  }

  /**
   * 输入确认框
   * @param title
   * @param content
   * @param callback
   * @param type
   * @param confirmText
   * @param cancelText
   */
  static inputConfirm(title = '输入框', content = '请输入', callback, type = 'blue', confirmText = '确认', cancelText = '取消') {
    $.confirm({
      animation: 'top',
      keyboardEnabled: true,
      type: type,
      title: title,
      content: '<input class="form-control" id="inputConfirm" type="text" placeholder="' + content + '"/>',
      buttons: {
        ok: {
          text: confirmText,
          btnClass: 'btn-primary',
          action: function () {
            if (callback) {
              callback(true, $('#inputConfirm').val());
            }
          }
        },
        cancel: {
          text: cancelText,
          action: function () {
            if (callback) {
              callback(false, $('#inputConfirm').val());
            }
          }
        }
      }
    });
  }
}
